@font-face {
    font-family: 'HeyWow, Arial, Helvetica, sans-serif';
    src: url('../assets/fonts/HeyWow/HeyWow-Regular.woff2') format('woff2'),
        url('../assets/fonts/HeyWow/HeyWow-Regular.woff') format('woff');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'HeyWow, Arial, Helvetica, sans-serif';
    src: url('../assets/fonts/HeyWow/HeyWow-Bold.woff2') format('woff2'),
        url('../assets/fonts/HeyWow/HeyWow-Bold.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: 'HeyWow, Arial, Helvetica, sans-serif';
    src: url('../assets/fonts/HeyWow/HeyWow-Light.woff2') format('woff2'),
        url('../assets/fonts/HeyWow/HeyWow-Light.woff') format('woff');
    font-weight: lighter;
    font-display: swap;
}

@font-face {
    font-family: 'HeyWow, Arial, Helvetica, sans-serif';
    src: url('../assets/fonts/HeyWow/HeyWow-Medium.woff2') format('woff2'),
        url('../assets/fonts/HeyWow/HeyWow-Medium.woff') format('woff');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'HeyWow, Arial, Helvetica, sans-serif';
    src: url('../assets/fonts/HeyWow/HeyWow-ExtraBold.woff2') format('woff2'),
        url('../assets/fonts/HeyWow/HeyWow-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-display: swap;
}

* {
    font-family: 'HeyWow, Arial, Helvetica, sans-serif' !important;
}

/** React Datepicker */
.react-datepicker-popper
    .react-datepicker__time-container
    .react-datepicker__time-list
    li.react-datepicker__time-list-item {
    color: black;
}

#register-club-component .ant-steps-item-icon {
    background-color: unset;
}

#register-club-component .anticon.anticon-check.ant-steps-finish-icon {
    vertical-align: 0 !important;
}

#register-club-component .ant-steps-item-finish .ant-steps-item-icon {
    background-color: white;
    color: #1f1d1d;
    border-color: white;
}

#register-club-component
    .ant-steps-item-finish
    .ant-steps-item-icon
    .ant-steps-icon {
    color: black;
}

#register-club-component .ant-steps-item-finish .ant-steps-item-tail::after {
    background-color: white;
    border-width: 1px;
}

#register-club-component
    .ant-steps-item-finish
    .ant-steps-item-content
    .ant-steps-item-title {
    color: white;
    @apply font-bold;
}

#register-club-component
    .ant-steps-item.ant-steps-item-process.ant-steps-item-active
    .ant-steps-item-icon {
    background-color: #c72223 !important;
    border-color: #8b1818;
    border-width: 4px;
}

#register-club-component
    .ant-steps-item.ant-steps-item-process.ant-steps-item-active
    .ant-steps-item-icon
    .ant-steps-icon {
    color: transparent !important;
}

#register-club-component
    .ant-steps-item.ant-steps-item-process.ant-steps-item-active
    .ant-steps-item-content
    .ant-steps-item-title {
    color: #fa3737;
    @apply font-bold;
}

#register-club-component
    .ant-steps-item.ant-steps-item-process.ant-steps-item-active
    .ant-steps-item-container
    .ant-steps-item-tail::after {
    border-color: #292727;
    border-width: 1px !important;
}

#register-club-component
    .ant-steps-item.ant-steps-item-wait
    .ant-steps-item-container
    .ant-steps-item-tail::after {
    border-color: #292727;
    border-width: 1px !important;
}

#register-club-component
    .ant-steps-item.ant-steps-item-wait
    .ant-steps-item-icon {
    border-color: #292727 !important;
    border-width: 2px;
    color: transparent;
}

#register-club-component
    .ant-steps-item.ant-steps-item-wait
    .ant-steps-item-icon
    .ant-steps-icon {
    color: transparent !important;
}

#register-club-component
    .ant-steps-item.ant-steps-item-wait
    .ant-steps-item-content
    .ant-steps-item-title {
    color: rgba(255, 255, 255, 0.4);
}

#register-club-component .ant-form-item .ant-input,
#register-club-component .ant-form-item .ant-select-selector {
    background: transparent;
    color: white;
    border-radius: 7px;
    border-color: #373434;
    @apply font-medium;
}

#register-club-component .ant-form-item .ant-input.ant-input-status-error {
    border-color: #ff4d4f !important;
}

#register-club-component .ant-form-item .ant-input:focus,
#register-club-component .ant-form-item .ant-select-open .ant-select-selector {
    border-color: #838383;
    box-shadow: none;
}

#register-club-component
    .ant-form-item
    .ant-select
    .ant-select-arrow
    .ant-select-suffix {
    color: white !important;
}

#register-club-component
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none;
}

#register-club-component .ant-form-item .ant-form-item-label label {
    @apply text-base font-semibold;
    color: #777777;
}

#register-club-component .ant-form-item .ant-input-affix-wrapper {
    background: transparent;
    color: white;
    border-radius: 7px;
    border-color: #373434;
    @apply font-medium;
}

#register-club-component
    .ant-form-item
    .ant-input-affix-wrapper.ant-input-affix-wrapper-status-error {
    border-color: #ff4d4f !important;
}

#register-club-component
    .ant-form-item
    .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
    border-color: #838383;
    box-shadow: none;
}

#register-club-component
    .ant-form-item
    .ant-input-affix-wrapper
    .ant-input-prefix {
    margin-right: unset !important;
}

#register-club-component .ant-form-item .ant-input-affix-wrapper .ant-input {
    border-radius: unset;
}

#register-club-component .ant-upload.ant-upload-drag {
    color: white;
    border-radius: 10px;
    background-color: #201e1e;
    border-color: rgba(168, 168, 168, 0.25);
}

#register-club-component .ant-upload.ant-upload-drag .ant-upload-text {
    color: #abaaaa !important;
    @apply font-semibold;
}

#register-club-component .ant-upload.ant-upload-drag .ant-upload-hint {
    color: #828282 !important;
    opacity: 60%;
}

#register-club-component
    .ant-form-vertical.step-4
    .ant-form-item
    .ant-form-item-row {
    /*@apply md:flex-col-reverse flex-col*/
}

#register-club-component .ant-form-vertical.step-4 .divider {
    @apply md:hidden !important;
}

#register-club-component .ant-form-vertical .ant-form-item {
    @apply md:flex-col flex-row;
}

#register-club-component .ant-steps {
    @apply overflow-auto;
}

#register-club-component
    .ant-form-item.upload-item
    .ant-form-item-control-input-content
    > span {
    display: flex;
}

#register-club-component
    .ant-form-item.upload-item
    .ant-form-item-control-input-content
    .ant-upload-list {
    @apply ml-10;
}

.invite-modal {
    background-color: #201e1e;
}

.invite-modal .ant-modal-header,
.invite-modal .ant-modal-body {
    background-color: #201e1e;
    color: white;
}

.invite-modal .ant-modal-content {
    box-shadow: none;
}

.invite-modal .ant-modal-header .ant-modal-title,
.invite-modal .ant-modal-close-x {
    color: white;
}

.styles-module_slider__o0fqa {
    flex-direction: row;
}

.ant-switch-checked {
    background-color: rgb(67 174 91) !important;
}

.ant-carousel .slick-prev {
    left: 0px !important;
    z-index: 1 !important;
}

.ant-carousel .slick-next {
    right: 1.3rem !important;
    z-index: 1 !important;
}

.ant-carousel .slick-prev::before {
    content: '' !important;
}

.ant-carousel .slick-next::before {
    content: '' !important;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
    color: unset !important;
    font-size: unset;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
    color: unset !important;
}

.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
    overflow-x: auto !important;
}
